import { useState } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { SetRequired } from 'type-fest'
import { Box } from 'grommet'

import { Pill, Select, SelectListItem, SelectProps, Text } from '@cutover/react-ui'
import { FieldProps, getInputProps } from './form-fields'
import { fetchRunbooks, UseRunbookParams } from 'main/services/queries/use-runbooks'
import { RunbookListRunbook } from 'main/services/queries/types'
import { useLanguage } from 'main/services/hooks'
import { templateStatusMap } from 'main/components/runbook/modals/link-template/link-template-select/link-template-select'

const DEFAULT_LIMIT = 10

type RunbookSelectProps<TRunbookType extends Record<any, any>> = Omit<
  SelectProps<TRunbookType>,
  'renderOption' | 'labelKey' | 'valueKey' | 'filterKeys' | 'options'
>
type OptionType = SetRequired<Partial<RunbookListRunbook>, 'id' | 'name'>

export type RunbookFieldProps<TFieldValues extends FieldValues, TRunbookOption extends OptionType = OptionType> = Omit<
  FieldProps<RunbookSelectProps<OptionType>, TFieldValues>,
  'onChange' | 'defaultValue' | 'defaultOptions'
> & {
  defaultOptions?: TRunbookOption[]
  initialValue?: number
  params?: UseRunbookParams
  onChange?: (option: TRunbookOption) => void
  accountSlug: string
  mode?: 'runbook' | 'template'
}

export function RunbookSelectField<TFieldValues extends FieldValues, TRunbookOption extends OptionType = OptionType>({
  params = { limit: DEFAULT_LIMIT },
  mode = 'runbook',
  ...props
}: RunbookFieldProps<TFieldValues, TRunbookOption>) {
  const [isLoading, setLoading] = useState(false)
  const formContext = useFormContext<TFieldValues>()
  const inputProps = getInputProps<TFieldValues>({ ...props, formContext })
  const { control } = formContext
  const { t } = useLanguage('common', {
    keyPrefix: 'runbook'
  })

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <>
            <Select<TRunbookOption>
              {...props}
              {...inputProps}
              labelKey="name"
              icon="search"
              valueKey="id"
              minChars={0}
              inputRef={ref}
              loading={isLoading}
              // @ts-ignore
              loadOptions={async input => {
                setLoading(true)
                const resp = await fetchRunbooks({
                  ...params,
                  accountId: props.accountSlug,
                  q: input,
                  sort_by: 'copies_count'
                })
                setLoading(false)
                return resp.runbooks
              }}
              renderOption={(runbookOption, renderProps) => {
                const color = templateStatusMap[runbookOption?.template_status || 'template_draft']?.color
                const label = t(templateStatusMap[runbookOption?.template_status || 'template_draft']?.text)
                return (
                  <SelectListItem
                    {...renderProps}
                    label={runbookOption.name}
                    suffix={
                      mode === 'template' && (
                        <Box direction="row" gap="xsmall">
                          <Text color="text-light">{t('copiesCount', { count: runbookOption.copies_count })}</Text>
                          <Pill color={color} label={label} />
                        </Box>
                      )
                    }
                  />
                )
              }}
              defaultValue={value}
              onChange={onChange}
            />
          </>
        )
      }}
    />
  )
}
