import { useEffect, useState } from 'react'
import * as yup from 'yup'

import { Box, Checkbox, Text, useNotify } from '@cutover/react-ui'
import { ActiveAccountModel } from 'main/data-access'
import { DataSourceValueModel } from 'main/data-access/models/data-source-value-model'
import { apiClient_UNSTABLE } from 'main/services/api'
import { useLanguage } from 'main/services/hooks'
import { FolderSelectField, FormModal, RunbookSelectField } from 'main/components/shared/form'
import { RunbooksResponseMetaFull, useRunbooks } from 'main/services/queries/use-runbooks'
import { FolderListFolder } from 'main/services/queries/types'

type CreateTemplatesFromDataSourceModalProps = {
  open: boolean
  onClose: () => void
  customFieldId: number
  accountId: number
}

const MAX_TEMPLATES = 250

type CreateTemplatesFromDataSourceFormType = yup.InferType<ReturnType<typeof buildCreateTemplateSchema>>
const buildCreateTemplateSchema = () => {
  return yup.object({
    template: yup
      .object({
        id: yup.number().required()
      })
      .required(),
    projectId: yup.number().required()
  })
}

export const CreateTemplatesFromDataSourceModal = ({
  open,
  onClose,
  customFieldId,
  accountId
}: CreateTemplatesFromDataSourceModalProps) => {
  const { t } = useLanguage('dataSources', { keyPrefix: 'createTemplatesModal' })
  const [selected, _] = DataSourceValueModel.useSelectedState({ customFieldId, accountId })
  const account = ActiveAccountModel.useGet()
  const notify = useNotify()
  const [projects, setProjects] = useState<FolderListFolder[] | undefined>(undefined)
  const [showOnlyApprovedTemplates, setShowOnlyApprovedTemplates] = useState(true)

  // TODO: This hook returns account meta, including projects.
  // Meta should be stored in global state when account endpoint is first called instead.
  const { data } = useRunbooks({
    limit: 0, // no need to fetch runbooks, just account meta
    meta: true,
    accountId: account.slug
  })

  useEffect(() => {
    if (data) {
      setProjects((data.meta as RunbooksResponseMetaFull).projects)
    }
  }, [data])

  const defaultValues = {
    template: undefined
  }

  const handleSubmit = async (data: CreateTemplatesFromDataSourceFormType) => {
    try {
      await apiClient_UNSTABLE.patch<any>('runbooks/bulk_create_from_data_source_values', {
        data_source_value_ids: selected.slice(0, MAX_TEMPLATES),
        template_id: data.template.id,
        project_id: data.projectId
      })
      onClose()
      notify.success(t('toasters.success', { count: selected.length }), { title: t('toasters.successTitle') })
    } catch {
      notify.success(t('toasters.error'), { title: t('toasters.errorTitle') })
    }
  }

  return (
    <FormModal<CreateTemplatesFromDataSourceFormType>
      title={t('title')}
      open={open}
      onClose={onClose}
      confirmText={t('submitText', { count: selected.length })}
      schema={buildCreateTemplateSchema()}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    >
      <Box gap="medium">
        {selected.length > MAX_TEMPLATES ? (
          <Text>{t('textMax', { count: selected.length, max: MAX_TEMPLATES })}</Text>
        ) : (
          <Text>{t('text', { count: selected.length })}</Text>
        )}
        <Checkbox
          onChange={() => setShowOnlyApprovedTemplates(!showOnlyApprovedTemplates)}
          checked={showOnlyApprovedTemplates}
          label={t('onlyDisplayApprovedTemplates')}
        />
        <RunbookSelectField<CreateTemplatesFromDataSourceFormType>
          name="template"
          label={t('referenceTemplate')}
          mode="template"
          params={{
            limit: 10,
            template_type: 'default',
            meta: true,
            fields: ['id', 'name', 'template_status', 'copies_count'],
            ts: showOnlyApprovedTemplates ? ['template_approved'] : []
          }}
          accountSlug={account.slug}
          key={showOnlyApprovedTemplates.toString()}
        />
        <FolderSelectField<CreateTemplatesFromDataSourceFormType>
          name="projectId"
          label={t('folderSelect')}
          folders={projects ?? []}
          clearable={false}
          loading={projects === undefined}
        />
      </Box>
    </FormModal>
  )
}
