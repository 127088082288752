import { atomFamily, selectorFamily } from 'recoil'
import { isPlainObject } from 'lodash'

import { DataSourceValue, DataSourceValuesResponse } from 'main/services/queries/types'
import { apiClient_UNSTABLE } from 'main/services/api'
import { applyFilterPipeline } from 'main/services/data-source-values/filtering/filter-map'
import { appliedFilterState } from '../../filters'
import { buildDataSourceValueFilterContext } from 'main/services/data-source-values/filtering/filter-context'

export type TemplateCreateStatus = {
  current: number
  total: number
}

const defaultResponse = {
  data_source_values: [],
  data_source_values_map: { templates: {} },
  template_create_status: undefined
}

export const dataSourceValues_INTERNAL = atomFamily<
  DataSourceValuesResponse | undefined,
  { customFieldId: number; accountId: number }
>({
  key: 'data-source-values',
  default: async ({ customFieldId, accountId }) => {
    const { data } = await apiClient_UNSTABLE.get<DataSourceValuesResponse>(
      `data_source_values?cf_id=${customFieldId}&account_id=${accountId}`
    )

    return data
  }
})

export const dataSourceValuesSelected = atomFamily<number[], { customFieldId: number; accountId: number }>({
  key: 'data-source-values-selected',
  default: []
})

export const dataSourceValuesFilter = selectorFamily<DataSourceValue[], { customFieldId: number; accountId: number }>({
  key: 'data-source-values:filter-data',
  get:
    ({ customFieldId, accountId }) =>
    ({ get }) => {
      const filters = get(appliedFilterState)
      const { data_source_values, data_source_values_map } =
        get(dataSourceValues_INTERNAL({ customFieldId, accountId })) ?? defaultResponse

      const filteredValues = applyFilterPipeline(
        data_source_values,
        filters,
        buildDataSourceValueFilterContext(filters, { data_source_values_map, data_source_values })
      )
      return filteredValues.map(value => ({
        ...value,
        templates: (data_source_values_map.templates as Record<number, number>)[value.id] || 0
      }))
    }
})

export const dataSourceValuesCount = selectorFamily<
  { valuesCount: number; filteredCount: number },
  { customFieldId: number; accountId: number }
>({
  key: 'data-source-values:count',
  get:
    ({ customFieldId, accountId }) =>
    ({ get }) => {
      const { data_source_values } = get(dataSourceValues_INTERNAL({ customFieldId, accountId })) ?? defaultResponse
      const filtered = get(dataSourceValuesFilter({ customFieldId, accountId }))

      return {
        valuesCount: data_source_values.length,
        filteredCount: filtered.length
      }
    }
})

export const dataSourceValuesOptions = selectorFamily<
  Record<string, any>,
  { customFieldId: number; accountId: number }
>({
  key: 'data-source-values:options',
  get:
    ({ customFieldId, accountId }) =>
    ({ get }) => {
      const { data_source_values: values } =
        get(dataSourceValues_INTERNAL({ customFieldId, accountId })) ?? defaultResponse
      const options: Record<string, any> = {}

      values.forEach(option => {
        Object.entries(option.values).forEach(([key, value]) => {
          if (!options[key]) {
            options[key] = new Set()
          }

          if (Array.isArray(value)) {
            value.forEach(v => v !== '' && options[key].add(v.toString()))
          } else if (!isPlainObject(value) && value !== '') {
            options[key].add(value.toString())
          }
        })
      })

      Object.keys(options).forEach(key => {
        options[key] = Array.from(options[key]).map(value => ({ label: value, value }))
      })

      return options
    }
})

export const dataSourceTemplateCreateStatus = selectorFamily<
  TemplateCreateStatus | undefined,
  { customFieldId: number; accountId: number }
>({
  key: 'data-source-values:template-creation-status',
  get:
    ({ customFieldId, accountId }) =>
    ({ get }) => {
      const { template_create_status } = get(dataSourceValues_INTERNAL({ customFieldId, accountId })) ?? defaultResponse

      return template_create_status
    }
})
