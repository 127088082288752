import { DataSourceValue } from 'main/services/queries/types'
import {
  useDataSourceValuesSelectedState,
  useGetDataSourceValuesCount,
  useGetDataSourceValuesOptions,
  useGetDataSourceValuesTemplateCreateStatus,
  useGetFilteredDataSourceValues,
  useRefetchDataSourceValuesCallback
} from '../hooks/recoil/data-source-value'
import { TemplateCreateStatus } from 'main/recoil/shared/models/data-source-values/data-source-values'

export type DataSourceValueModelType = {
  useGetFilteredValues: ({
    customFieldId,
    accountId
  }: {
    customFieldId: number
    accountId: number
  }) => DataSourceValue[]
  useGetCount: ({ customFieldId, accountId }: { customFieldId: number; accountId: number }) => {
    valuesCount: number
    filteredCount: number
  }
  useGetOptions: ({
    customFieldId,
    accountId
  }: {
    customFieldId: number
    accountId: number
  }) => Record<string, string[]>
  useRefetchValuesCallback: () => ({ customFieldId, accountId }: { customFieldId: number; accountId: number }) => void

  useSelectedState: ({
    customFieldId,
    accountId
  }: {
    customFieldId: number
    accountId: number
  }) => [number[], (ids: number[]) => void]

  useGetTemplateCreateStatus: ({
    customFieldId,
    accountId
  }: {
    customFieldId: number
    accountId: number
  }) => TemplateCreateStatus | undefined
}

export const DataSourceValueModel: DataSourceValueModelType = {
  useGetFilteredValues: useGetFilteredDataSourceValues,
  useGetOptions: useGetDataSourceValuesOptions,
  useGetCount: useGetDataSourceValuesCount,
  useRefetchValuesCallback: useRefetchDataSourceValuesCallback,

  // Data source value selection
  useSelectedState: useDataSourceValuesSelectedState,

  // Template creation
  useGetTemplateCreateStatus: useGetDataSourceValuesTemplateCreateStatus
}
