import { useRecoilCallback, useRecoilValue } from 'recoil'
import { produce } from 'immer'

import { DataSourceValueModelType } from 'main/data-access/models/data-source-value-model'
import {
  dataSourceTemplateCreateStatus,
  dataSourceValues_INTERNAL,
  dataSourceValuesCount,
  dataSourceValuesFilter,
  dataSourceValuesOptions,
  dataSourceValuesSelected
} from 'main/recoil/shared/models/data-source-values/data-source-values'
import { apiClient_UNSTABLE } from 'main/services/api'
import { DataSourceValuesResponse } from 'main/services/queries/types'

export const useGetFilteredDataSourceValues: DataSourceValueModelType['useGetFilteredValues'] = ({
  customFieldId,
  accountId
}) => {
  return useRecoilValue(dataSourceValuesFilter({ customFieldId, accountId }))
}

export const useGetDataSourceValuesCount: DataSourceValueModelType['useGetCount'] = ({ customFieldId, accountId }) => {
  return useRecoilValue(dataSourceValuesCount({ customFieldId, accountId }))
}

export const useGetDataSourceValuesOptions: DataSourceValueModelType['useGetOptions'] = ({
  customFieldId,
  accountId
}) => {
  return useRecoilValue(dataSourceValuesOptions({ customFieldId, accountId }))
}

export const useRefetchDataSourceValuesCallback = () => {
  return useRecoilCallback(
    ({ set }) =>
      async ({ customFieldId, accountId }) => {
        const { data } = await apiClient_UNSTABLE.get<DataSourceValuesResponse>(
          `data_source_values?cf_id=${customFieldId}&account_id=${accountId}`
        )
        set(dataSourceValues_INTERNAL({ customFieldId, accountId }), data)
      },
    []
  )
}

export const useGetDataSourceValuesTemplateCreateStatus: DataSourceValueModelType['useGetTemplateCreateStatus'] = ({
  customFieldId,
  accountId
}) => {
  return useRecoilValue(dataSourceTemplateCreateStatus({ customFieldId, accountId }))
}

export const useDataSourceValuesSelectedState: DataSourceValueModelType['useSelectedState'] = ({
  customFieldId,
  accountId
}) => {
  const selected = useRecoilValue(dataSourceValuesSelected({ customFieldId, accountId }))

  const setSelected = useRecoilCallback(
    ({ set }) =>
      (ids: number[]) => {
        set(dataSourceValuesSelected({ customFieldId, accountId }), prevState =>
          produce(prevState, draft => {
            if (ids.length === 1) {
              const id = ids[0]
              const index = draft.indexOf(id)
              if (index === -1) {
                draft.push(id) // Add if not present
              } else {
                draft.splice(index, 1) // Remove if present
              }
            } else {
              return ids
            }
          })
        )
      },
    [customFieldId, accountId]
  )

  return [selected, setSelected]
}
