import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { Box, Message, RightPanel } from '@cutover/react-ui'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { useRightPanelTypeValue, useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { RunbookEditRunbook, RunbookListRunbook } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing/hooks'
import { MarkedIncidentSensitiveResponse } from 'main/services/api/data-providers/user/user-channel-response-types'

// NOTE: this whole panel is functionally pointless, more complexity that value, and should be removed

export const RunbookLinkedPanel = () => {
  const { runbook } = useRightPanelTypeValue('runbook-linked')

  return runbook ? <RunbookLinked runbook={runbook} /> : null
}

const RunbookLinked = ({ runbook }: { runbook: RunbookEditRunbook }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'linkedRunbooksPanel' })
  const { listen } = useUserWebsocket()
  const [sensitiveRunbook, setSensitiveRunbook] = useState(false)

  const handleWebsocketResponse = (data: MarkedIncidentSensitiveResponse) => {
    if (data?.meta?.headers.request_method === 'runbook_marked_restricted' && linkedRunbook) {
      if (linkedRunbook?.id === data.runbook_id) setSensitiveRunbook(true)
    }
  }

  useEffectOnce(() => {
    listen(data => {
      handleWebsocketResponse(data as MarkedIncidentSensitiveResponse)
    })
  })

  const { openRightPanel, closeRightPanel } = useSetActiveRightPanelState()
  const { toRunbook } = useRouting()

  const linkedRunbook = useMemo(() => {
    const linked = runbook.linked_runbook_details?.linked_resource
    if (linked) {
      setSensitiveRunbook(!linked.id)
      return linked
    }
    return null
  }, [runbook])

  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const readPermission = !!runbook.linked_runbook_details?.linked_resource_permissions?.read
  const showLinkedRunbook = linkedRunbook && readPermission && !sensitiveRunbook
  const createLinkTo = useCallback(
    (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
    [accountSlug]
  )

  return (
    <RightPanel
      title={t('title')}
      onClose={closeRightPanel}
      onBack={() => openRightPanel({ type: 'runbook-edit', runbookId: runbook.id })}
    >
      <>
        {(!readPermission || sensitiveRunbook) && (
          <Box data-testid="linked-parent-warning" margin={{ bottom: 'small' }}>
            <Message type="info" message={t('alert')} />
          </Box>
        )}
        {showLinkedRunbook && (
          <RunbookListItem
            key={linkedRunbook.id}
            runbook={linkedRunbook as RunbookListRunbook}
            createLinkTo={createLinkTo}
          />
        )}
      </>
    </RightPanel>
  )
}
